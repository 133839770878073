import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {Card} from './Card';

import { useBlocker } from 'react-router-dom';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';

import {
	logIt,
	empty,
	getRandomUrlAppendage
} from '__globals/global-functions';

import { addCss, addCSSClass, removeCSSClass } from '__globals/css-functions';

import {
	goNextPage
} from '__globals/page-flow';

// import {
// 	usePrompt
// } from '__globals/usePrompt.tsx';

import * as GC from '__globals/GLOBAL-CONSTANTS.js';

import _IWGameResult from "_pages/InstantWin/_Results/_IWGameResult/_IWGameResult";

import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';

import {sendAction} from "_features/external-integration/external-integration-slice";
import PageFlow from "../../__routes/PageFlow";
import {PageBackgroundImages} from "../../../_components/PageBackgroundImages/PageBackgroundImages";
import {PageCopy} from "../../../_components/PageCopy/PageCopy";
import {PromocracyChooser} from "_pages/Variations/Promocracy/PromocracyChooser/PromocracyChooser";
import {retrievePromocracyParticipant} from "../../../__globals/promocracy-functions";
import {postEntry} from "../../../_features/entries/entry-slice";

let addOn = getRandomUrlAppendage("rd");

export const IWGameMatch = props => {

	logIt( "INSTANT WIN GAME MATCH PAGE IS CALLED", "", false );

	const dispatch = useDispatch();
	
	const [nextPage, setNextPage] = useState(undefined);
	
	const state = useSelector( state => state );
	//logIt( "STATE at IWGameMatch", state, true );
	
	const promotion = useSelector(state => state.promotion);
	//logIt( "PROMOTION at IWGameMatch", promotion, true );
	
	let entry = useSelector(state => state.entry);
	//logIt( "ENTRY at IWGameMatch", entry, true);

	let gameClaim = useSelector(state => state.entry.gameClaim);
	//logIt( "GAME CLAIM at IWGameMatch", gameClaim, true);

	let variables = useSelector(state => state.entry.variables);
	//logIt( "GAME CLAIM at IWGameMatch", gameClaim, true);

	let error = useSelector(state => state.entry.error);
	logIt( "ERROR AT IWGameMatch", error, true );

	let entryUpdated = useSelector(state => state.entry.entryUpdated);
	//logIt( "entryUpdated AT IWGameMatch", error, true );

	let winFormUpdated = useSelector(state => state.entry.winFormUpdated);
	//logIt( "winFormUpdated AT IWGameMatch", error, true );

	/** LOCAL VARIABLES */
	const [allowUsePrompt, setAllowUsePrompt] = useState( false);

	const [showTitle, setShowTitle] = useState( false);
	const [showCopy, setShowCopy] = useState( true);

    const [flipCount, setFlipCount] = useState( 0 );
	const [resultImage, setResultImage] = useState( '' );
	const [resultCSS, setResultCSS] = useState( '' );

	const [animationGo, setAnimationGo] = useState( false );
	const [goNext, setGoNext] = useState(false);

	const [chooserOn, setChooserOn] = useState(false);

	//const prompt = usePrompt( (allowUsePrompt && (flipCount >= 0)), "If you leave this page, you will invalidate your game play.  Choose 'Cancel' to return and finish the game. Click 'OK' to continue leaving.");

	/** HELPER FUNCTIONS **/
    const flipCard = (cardNo) => {
    	let divName = "card" + cardNo;
    	addCSSClass(divName, "is-flipped");
    	addCSSClass(divName, "shadow-off");
		let fc = flipCount;
		fc++;
		setFlipCount( fc );
    }
	
	const showResults = () => {
		setTimeout(function() {
			addCSSClass( "game_results_screen", "result-screen-fade-in" );
			addCSSClass( "game_results_options", "result-options-fade-in" );
			addCSSClass( "game_cards", "result-match-game" );
			addCSSClass( "game_match", "result-game-done" );
			setAnimationGo( true );
			setChooserOn( true );
			if ( gameClaim?.parameters?.result === GC.RESULT_STANDARD ) {
				setFlipCount(-2);
			}
		}, promotion.configuration.config.games.match.resultDelay);			
	}

	const showForm = () => {
		addCSSClass( "redeem_form", "result-redeem-fade-in" );
		addCSSClass( "redeem_button", "btn-redeem-out" );
		addCSSClass( "form_win_screen_show_results_button", "btn-redeem-out" );
	}

	const handleAction = ( action, value ) => {
		if ( action === "THANKYOU" ) {
			setFlipCount( -1 );
		}
		dispatch( sendAction(promotion, action, value) );
	}

	const handlePromocracyParticipantPick = ( participantKey ) => {
		let promocracyParticipant = retrievePromocracyParticipant( promotion.configuration, participantKey );
		logIt( "promocracyParticpant at handlePromocracyParticipantPick", promocracyParticipant, true );
		if ( !empty(promocracyParticipant) ) {
			let newEntry = JSON.parse(JSON.stringify(entry));
			newEntry.entry.entry["promocracyID"] = newEntry.entry.entry.emailAddress + "_" + promocracyParticipant["key"];
			newEntry.entry.entry["promocracyLogoImage"] = promocracyParticipant.logoImage;
			newEntry.entry.entry["promocracyDisplayName"] = promocracyParticipant.displayName;
			logIt( "newEntry at handlePromocracyParticipantPick", newEntry, true );
			dispatch( postEntry(newEntry, null, null, promotion.configuration.name, participantKey) );
		}




		// check to see if max daily entries has been reached
		// store in a daily local storage
		// prepare a new entry for new participant
		// clear the current entry
		// reset the promocracy participant
		// store entry for new participant and get a new game claim
		// set go next to flow back to the iw page



		// let addEntry = {"entry":{}};
		// addEntry.entry.prizePick = prizeKey;
		// let items = prizePicks.map((pPick, key) => {
		//     if ( pPick.key == prizeKey) {
		//         addEntry.entry.prizeDescription = pPick.prizeDescription;
		//     }
		// });
		// logIt( "TEST PRIZE PICK ENTRY", addEntry, true );
		// dispatch( updateEntry(addEntry) );
	}

	/** END HELPER FUNCTIONS **/

	/** USE EFFECT HOOKS **/
	useEffect(() => {
		if ( !empty(promotion) ) {
			setAllowUsePrompt( promotion.configuration?.config?.games?.allowUsePrompt );
		}
	}, [promotion]);

	useEffect(() => {
		if ( flipCount >= promotion.configuration.config.games.match.cardCount ) {
			showResults();
		} else if ( flipCount === -1  ) {
			setGoNext( true );
		}
    }, [flipCount]);

	useEffect(() => {
		if ( !empty(gameClaim) ) {
			setResultImage( gameClaim.parameters.resultImage );
			setResultCSS( gameClaim.parameters.resultCss );
		}
	}, [gameClaim]);

	useEffect(() => {
		if ( (promotion?.retrieved) && !empty(resultCSS) ) {
			addCss( promotion, resultCSS );
		}
	}, [resultCSS]);

	useEffect(() => {
		if ( !empty(error) ) {
			alert( JSON.stringify(error) );
			//setGoNext( true );
		}
	}, [error] );

	useEffect(() => {
		if ( !empty(entryUpdated) && (entryUpdated === true) ) {
			//goNextPage( promotion, GC.PAGE_IW_MATCH, error );
			setFlipCount( -1 );
		}
	}, [entryUpdated] );

	useEffect(() => {
		if ( !empty(winFormUpdated) && (winFormUpdated === true) ) {
			setFlipCount( -1 );
		}
	}, [winFormUpdated] );

	/** END USE EFFECT HOOKS **/


	if ( goNext ) {

		return( <PageFlow page={GC.PAGE_IW_MATCH} error={error} /> );

	} else {

		return (

			<React.Fragment>

				<TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title}/>

				<div id="game_match">
					<table>
						<tbody>
						<tr>
							<td className="page-visual">

								<PageBackgroundImages
									promotion={promotion}
									pageKey={"iw-match"}
								/>

							</td>
							<td className="page-content">

								<div id='match_game_content'>

									<PageCopy
										promotion={promotion}
										configKey={"gamePage"}
										jsonKey={GC.PAGE_IW_MATCH}
										entry={entry}
									/>

								</div>


								<div id='game_match_board'>

									<div id='game_cards'>

										<div id='dv_no_refresh_instructions'>
											Do not refresh your browser,
											you will lose this game play.
										</div>

										{(promotion.configuration.config.games.match.cardCount >= 1) &&
											<Card promotion={promotion} cardNo="1" flipCard={flipCard} addOn={addOn}
												  resultImage={resultImage}/>}

										{(promotion.configuration.config.games.match.cardCount >= 2) &&
											<Card promotion={promotion} cardNo="2" flipCard={flipCard} addOn={addOn}
												  resultImage={resultImage}/>}

										{(promotion.configuration.config.games.match.cardCount >= 3) &&
											<Card promotion={promotion} cardNo="3" flipCard={flipCard} addOn={addOn}
												  resultImage={resultImage}/>}

										{(promotion.configuration.config.games.match.cardCount >= 4) &&
											<Card promotion={promotion} cardNo="4" flipCard={flipCard} addOn={addOn}
												  resultImage={resultImage}/>}

										{(promotion.configuration.config.games.match.cardCount >= 5) &&
											<Card promotion={promotion} cardNo="5" flipCard={flipCard} addOn={addOn}
												  resultImage={resultImage}/>}

										{(promotion.configuration.config.games.match.cardCount >= 6) &&
											<Card promotion={promotion} cardNo="6" flipCard={flipCard} addOn={addOn}
												  resultImage={resultImage}/>}

										{(promotion.configuration.config.games.match.cardCount >= 7) &&
											<Card promotion={promotion} cardNo="7" flipCard={flipCard} addOn={addOn}
												  resultImage={resultImage}/>}

										{(promotion.configuration.config.games.match.cardCount >= 8) &&
											<Card promotion={promotion} cardNo="8" flipCard={flipCard} addOn={addOn}
												  resultImage={resultImage}/>}

										{(promotion.configuration.config.games.match.cardCount >= 9) &&
											<Card promotion={promotion} cardNo="9" flipCard={flipCard} addOn={addOn}
												  resultImage={resultImage}/>}

									</div>
								</div>

								{/** Component holding all the game results **/}
								<_IWGameResult promotion={promotion}
											   gameClaim={gameClaim}
											   entry={entry}
											   handleAction={handleAction}
											   showForm={showForm}
											   animationGo={animationGo}/>


								<div id="game_results_screen">
									<div className="view-desktop">
										<img
											src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/${resultImage}`}/>
									</div>
									<div className="view-phone" style={{display: 'none'}}>
										<img
											src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/${resultImage}`}/>
									</div>
								</div>

								<PromocracyChooser
									promotion={promotion}
									chooserOn={chooserOn}
									entry={entry}
									handlePromocracyParticipantPick={handlePromocracyParticipantPick}
								/>


							</td>
						</tr>
						</tbody>
					</table>


					<CustomContent/>

				</div>

			</React.Fragment>

		);

	}

}




