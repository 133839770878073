import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { Route, useParams } from 'react-router-dom';
import {Navigate, Outlet, useNavigate, useRoutes} from 'react-router-dom';
import ReactGA from 'react-ga';
//import ReactPixel from 'react-facebook-pixel';

// ROUTES
import BrowserRouterRoutes from '_pages/__routes/BrowserRouterRoutes';
// import IWRoutes from '_pages/__routes/IWRoutes';
// import SimplePromoRoutes from '_pages/__routes/SimplePromoRoutes';
			
// LAYOUT
import { Footer } from '_pages/_Footer/Footer';

// ERROR PAGE
import { Error } from '_pages/GameFlowPages/Error/Error';

import './App.css';

import packageJson from '../package.json';
import {LottieAnimation} from "./_pages/Variations/Animation/LottieAnimation/LottieAnimation";
import setupAxios from "./_api/AxiosSetup";
import {
	doesVariationExist,
	empty,
	logIt,
	getJsonFromUrl,
	getRandomUrlAppendage,
} from "./__globals/global-functions";
import {
	addCSSClass,
	removeCSSClass
} from "./__globals/css-functions";
import {fetchPromotion} from "./_features/promotion/promotion-slice";
import * as GC from "./__globals/GLOBAL-CONSTANTS";
import {goNextPage} from "./__globals/page-flow";
import {useCssAnimation} from "./__globals/animation";
import {VARIATION_PROMOCRACY} from "./__globals/GLOBAL-CONSTANTS";
import {isPromocracyOn, retrievePromocracyParticipant} from "./__globals/promocracy-functions";

/** TAG MANAGER **/
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
	gtmId: 'GTM-NF4ZR98X'
}

TagManager.initialize(tagManagerArgs);
/** END TAG MANAGER **/

global.appVersion = packageJson.version;

const App = (props) => {

	// CHECK HASH
	const dispatch = useDispatch();

	const navigate = useNavigate();

	// STATE VARIABLES
	//const state = useSelector( state => state );
	//logIt( "STATE in App", state, true );

	const promotion = useSelector(state => state.promotion);
	//logIt( "PROMOTION in App", promotion, true );

	// LOCAL VARIABLES
	const [isLoading, setLoading] = useState(true);
	const [lottieJson, setLottieJson] = useState();

	// REDIRECT TO SSL
	if ( window.location.hostname !== 'localhost' ) {
		if ( window.location.protocol !== 'https:' ) {
			window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
		}
	}



	// BLACKOUT THE PAGE
	document.body.style = 'background: black';
	document.documentElement.style = 'background: black';

	// FUNCTIONS
	/** Add a css page */
	const addCss = (css) => {
		var fileref=document.createElement("link");
		fileref.setAttribute("rel", "stylesheet")
		fileref.setAttribute("type", "text/css");
		let addOn = getRandomUrlAppendage("ver");
		fileref.setAttribute("href", `https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/css/${css}${addOn}`);
		document.getElementsByTagName("head")[0].appendChild(fileref);
	}
  
	/* Show and hide the rules */
	const showHiderules = () => {
		let rulesOverlay = document.getElementById('rules_overlay');
		let toggle = false;
		for ( var i=0; i < rulesOverlay.classList.length; i++ ) {
			if ( rulesOverlay.classList[i] === 'showDiv' ) {
				toggle = true;
			}
		}
		if ( toggle ) {
			removeCSSClass('rules_overlay', 'showDiv');
			addCSSClass('rules_overlay', 'hideDiv');
			removeCSSClass('rules_overlay_cover', 'showDiv');
			addCSSClass('rules_overlay_cover', 'hideDiv');
		} else {
			removeCSSClass('rules_overlay', 'hideDiv');
			addCSSClass('rules_overlay', 'showDiv');
			removeCSSClass('rules_overlay_cover', 'hideDiv');
			addCSSClass('rules_overlay_cover', 'showDiv');
		}
		window.scrollTo(0, 0);
	}
  
	/* Show or hide the menu */
	const showHideMenu = () => {
		let topNavElement = document.getElementById('main_nav');
		let toggle = false;
		for ( var i=0; i < topNavElement.classList.length; i++ ) {
			if ( topNavElement.classList[i] === 'showDiv' ) {
				toggle = true;
			}
		}
		if ( toggle ) {
			removeCSSClass('main_nav', 'showDiv');
			addCSSClass('main_nav', 'hideDiv');
			removeCSSClass('main_nav', 'showDiv');
			addCSSClass('main_nav', 'hideDiv');
		} else {
			removeCSSClass('main_nav', 'hideDiv');
			addCSSClass('main_nav', 'showDiv');
			removeCSSClass('main_nav', 'hideDiv');
			addCSSClass('main_nav', 'showDiv');
		}
		window.scrollTo(0, 0);
	}
  
	/* Link functions */
	const enterLink = () => {
		//goNextPage( promotion, GC.PAGE_SPLASH );
		//goNextPage( promotion, GC.PAGE_SPLASH );
		//window.location = '/#/gnp-redir';
		navigate("/enter");
	}
  
	// const logProgressLink = () => {
	// 	window.location = '/#/challenge-thank-you';
	// }
  
	const aboutLink = () => {
		navigate("/about");
	}
  
	// const faqLink = () => {
	// 	window.location = '/#/faq';
	// }
  
	const prizesLink = () => {
		navigate("/prizes");
	}
  
	// const galleryLink = () => {
	// 	window.location = '/#/gallery';
	// }

	const officialRulesLink = () => {
		showHiderules();
	}

	const privacyPolicyLink = (privacyPolicyLink) => {
		window.open( privacyPolicyLink, "_blank" );
	}

	const printPage = () => {
		window.print();
	}
  
	/* Set/choose language */
	const chooseLanguage = (event) => {
		let language = event.currentTarget.dataset.lang;
		dispatch(switchLanguage(promotion.configuration.name, promotion.configuration.currentLanguage ));
	}

	const onPageLoad = () => {

	};
	window.addEventListener('load', onPageLoad);

	useEffect(() => {
		
		setupAxios();
		let hostname = window.location.hostname;
		let winUrl = window.location.href;
		let params = getJsonFromUrl( winUrl );
		
		if ( hostname === "localhost" ) {
	    	
	    	//LIVE
	    	//hostname = "first-aid-beauty.readysetpromo.com";
	    	//hostname = "gabesdeals.com";
	    	
	    	//TEST
	    	//hostname = "gabes-back-to-school-2021.test.readysetpromo.com";
	    	//hostname = "simple.test.readysetpromo.com";
	    	//hostname = "wheel.test.readysetpromo.com";
	    	//hostname = "match.test.readysetpromo.com";
	    	//hostname = "slot.test.readysetpromo.com";
	    	//hostname = "giftwpurchase.test.readysetpromo.com";
	    	//hostname = "brand-superhero-slot.test.readysetpromo.com";
	    	//hostname = "brand-superhero-wheel.test.readysetpromo.com";
	    	//hostname = "brand-superhero-match.test.readysetpromo.com";
	    	//hostname = "lansing-customer-appreciation.test.readysetpromo.com";
	    	//hostname = "gabes-santa-spinner.test.readysetpromo.com";
	    	//hostname = "shake-up-the-holidays.test.readysetpromo.com";
	    	//hostname = "gabes-instant-jackpot.test.readysetpromo.com";
	    	//hostname = "gabes-santas-flip-to-win.test.readysetpromo.com";
	    	//hostname = "tommy-x-timberland.test.readysetpromo.com";
	    	//hostname = "challenge-promo.test.readysetpromo.com";
			//hostname = "summer-grilling.test.readysetpromo.com";
			//hostname = "findyorrik.test.readysetpromo.com";
	    	//hostname = "first-aid-beauty.test.readysetpromo.com";
	    	//hostname = "gabes.test.readysetpromo.com";
	    	//hostname = "mymkmomentcontest.test.readysetpromo.com";
	    	//hostname = "harvey.test.readysetpromo.com";
	    	//hostname = "fpl.test.readysetpromo.com";
	    	//hostname = "klass-chilito-loteria.test.readysetpromo.com";
	    	//hostname = "shake-it-up.test.readysetpromo.com";
	    	//hostname = "hall-of-flavors.test.readysetpromo.com";
	    	//hostname = "mizkan.test.readysetpromo.com";

			//hostname = "bamko-brandanything.test.readysetpromo.com";
			//hostname = "dollar-general.test.readysetpromo.com";
			//hostname = "lionslegacy.test.readysetpromo.com";
			//hostname = "summer-grilling-2023.test.readysetpromo.com";
			//hostname = "clover-valley.test.readysetpromo.com";
			//hostname = "reeses.test.readysetpromo.com";

			//hostname = "core-test-sun-fun-simple-sweeps.test.readysetpromo.com";
			//hostname = "core-test-sun-fun-match.test.readysetpromo.com";
			//hostname = "core-test-sun-fun-wheel.test.readysetpromo.com";
			//hostname = "core-test-sun-fun-slot.test.readysetpromo.com";
			//hostname = "core-test-sun-fun-match-configurable.test.readysetpromo.com";
			//hostname = "reeses.test.readysetpromo.com";
			//hostname = "dicksxnikegiveaway.test.readysetpromo.com"

			//hostname = "blueprint-iwmatch.test.readysetpromo.com";

			//hostname = "roadtowrestlemania.test.readysetpromo.com";

			//hostname = "example-funinthesun-simple.test.readysetpromo.com";
			//hostname = "example-funinthesun-iwmatch.test.readysetpromo.com";
			//hostname = "example-funinthesun-iwwheel.test.readysetpromo.com";
			//hostname = "example-funinthesun-iwslot.test.readysetpromo.com";
			//hostname = "example-funinthesun-iwconfigurable.test.readysetpromo.com";

			//hostname = "dg-cfa-feb-test.test.readysetpromo.com";
			//hostname = "dickswnbafanexperience.test.readysetpromo.com";

			//hostname = "local-wins-vegas-simple.test.readysetpromo.com";
			//hostname = "local-wins-vegas-slot.test.readysetpromo.com";
			//hostname = "local-wins-vegas-match.test.readysetpromo.com";

			//hostname = "dollargeneral-degree-match.test.readysetpromo.com";

			//hostname = "example-funinthesun-iwreveal.test.readysetpromo.com";

			//hostname = "trivia-test.test.readysetpromo.com";

			hostname = "raffle-demo.test.readysetpromo.com";

			//hostname = "dicksholidayshoppingsprint24.test.readysetpromo.com";

			//hostname = "knights-in-vegas.test.readysetpromo.com";

			//hostname = "skillz-test.test.readysetpromo.com";

			//hostname = "rallytoremember.org"

		}

		dispatch( fetchPromotion( hostname, params, props ) );

		if(promotion.retrieved) {

			// GA Tracking
			const trackingId = "UA-80071006-3"; // Replace with your Google Analytics tracking ID
			ReactGA.initialize(trackingId);
  
			// FB Tracking
			// if ( !empty(promotion.configuration.config) ) {
			// 	let fbPix = promotion.configuration.config.facebookPixel;
			// 	if ( !empty(fbPix) ) {
			// 		ReactPixel.init(fbPix);
			// 		ReactPixel.pageView();
			// 	}
			// }
      
			// CSS
			addCss("wrapper.css");
			addCss("form.css");
			addCss("registration.css");
			// addCss("other.css");
			if ( !empty(promotion.configuration.config) ) {
				if (promotion.configuration.type.value === "photo-contest") {
					addCss("upload.css");
				}
				if (promotion.configuration.type.value === "iw-wheel") {
					addCss("wheel.css");
				}
				if (promotion.configuration.type.value === "iw-match" ) {
					addCss("match.css");
				}
				if ( promotion.configuration.type.value === "iw-match-configurable" ) {
					addCss("match-configurable.css");
				}
				if (promotion.configuration.type.value === "iw-slot") {
					addCss("slot.css");
				}
				if (promotion.configuration.type.value === "photo-contest") {
					addCss("gallery.css");
				}
				if (promotion.configuration.type.value === "gift-with-purchase") {
					addCss("giftwithpurchase.css");
				}
				if (promotion.configuration.type.value === "challenge-promo") {
					addCss("challenge.css");
				}
				if (promotion.configuration.type.value === "trivia") {
					addCss("trivia.css");
				}
				if ( useCssAnimation(promotion.configuration.config) ) {
					addCss("animation.css");
				}
				if ( doesVariationExist(promotion.configuration.config.variations, GC.VARIATION_PICK_PRIZE) ) {
					addCss("pickyourprize.css");
				}
				addCss("thankyou.css");
				addCss("variables.css");
				if ( doesVariationExist(promotion.configuration.config.variations, GC.VARIATION_PROMOCRACY) ) {
					if ( isPromocracyOn(promotion.configuration) ) {
						let promocracyParticipant = retrievePromocracyParticipant(promotion.configuration, promotion.configuration.source);
						//alert( JSON.stringify(promocracyParticipant) );
						if ( !empty(promocracyParticipant) ) {
							addCss( "promocracy.css" );
						}
					}
				}
			}


			//addCss("chuck.css");

			if ( !empty(promotion) ) {
				(async () => {
					const response = await fetch(
						`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/json/animation/lottie/loading.json`
					);
					const parsed = await response.json();
					setLottieJson(parsed);
				})();
			}

			//addCSSClass("dv_loading", "hide");

			//;
			setTimeout(function() {
				//addCSSClass( "dv_game_mask", "cls-fadeout-mask" );
				//addCSSClass( "dv_intro_animation", "hide" );
				setTimeout(function() {
					//addCSSClass( "dv_game_mask", "cls-fadeout-mask" );
					//addCSSClass( "dv_intro_animation", "hide" );
					addCss("background.css");
				}, 50 );
				setLoading( false )
			}, 2000 );

		}
		// END CSS
    
	}, [dispatch, promotion.retrieved]);
	
	useEffect(() => {
		if ( !empty(isLoading) && !isLoading ) {
			//goNextPage( promotion, "App" );

		}
	}, [isLoading] );

	// useEffect(() => {
	// 	let winUrl = window.location.href;
	// 	if ( winUrl.indexOf("thank-you") > 0 ) {
	// 		window.location = "/#/";
	// 	}
	// }, []);



	if(isLoading) {
		
		//return (<div id="dv_loading" className="background"><div className='loading'><div className='loading-copy'>loading:<div className='scrollingwords'><span>promotion</span><span>interface</span><span>prizes</span><span>rules</span><span>probabilities</span><span>winning plays</span></div></div></div></div>);
		return(
			<div id="dv_loading" className='loading-on'>
				<div id="dv_lottie_animation" style={{height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
					<LottieAnimation
						loop={true}
						lottieJson={lottieJson}
						width={150}
						//height={introAnimationHeight}
					/>
				</div>
			</div>
		);

	} else {

		if ( promotion.fatalError || empty(promotion.configuration.config) ) {
			return(
				<>
					<div id="page_wrapper">
						<Route exact path="/error" component={Error} />
					</div>
				</>
			);
		} else {
			let copy = promotion.configuration.promoCopy["GLOBAL"];
			return (
				<>



					{/* <div className='background'><div className='loading'><div className='loading-copy'>loading:<div className='scrollingwords'><span>promotion</span><span>interface</span><span>prizes</span><span>rules</span><span>probabilities</span><span>winning plays</span></div></div></div></div> */}

					<div id="page_wrapper">
					
						{ ( promotion.configuration.config.languages.length > 1 ) && <div id="language">
							{(promotion.configuration.currentLanguage === "en") && <div class="language-es" onClick={chooseLanguage} data-lang="es">Es<span>panol</span></div>}
							{(promotion.configuration.currentLanguage === "es") && <div class="language-en" onClick={chooseLanguage} data-lang="en">En<span>glish</span></div>}
						</div>}
				
						<div id='rules_overlay' className="hideDiv">
							<div id='rules_overlay_content'>
								<div id='rules_close' onClick={showHiderules}><div className="rules-title">Official Rules</div><span>X</span></div>
								<iframe src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/${promotion.configuration.languagePath}rules.html`} scrolling='yes' frameBorder='0' className='iframerule'></iframe>
								<div id='rules_close_bottom' onClick={showHiderules}><span>X</span></div>
							</div>
						</div>
						<div id='rules_overlay_cover' onClick={showHiderules} className="hideDiv"></div>

				
						<div id="promo_wrapper">

						<div id="main_nav" className="showDiv">
							<ul className="top-menu">
								{(promotion.configuration.config.menus?.enter?.on) && <li>
									<span onClick={ enterLink }>
										{promotion.configuration.config.menus.enter.label[promotion.configuration.currentLanguage]}
									</span>
								</li>}
								{(promotion.configuration.config.menus?.logprogress?.on) && <li>
									<span onClick={ logProgressLink }>
										{promotion.configuration.config.menus.logprogress.label[promotion.configuration.currentLanguage]}
									</span>
								</li>}
								{(promotion.configuration.config.menus?.about?.on) && <li>
									<span onClick={ aboutLink }>
										{promotion.configuration.config.menus.about.label[promotion.configuration.currentLanguage]}
									</span>
								</li>}
								{(promotion.configuration.config.menus?.faq?.on) && <li>
									<span onClick={ faqLink }>
										{promotion.configuration.config.menus.faq.label[promotion.configuration.currentLanguage]}
									</span>
								</li>}
								{(promotion.configuration.config.menus?.prizes?.on) && <li>
									<span onClick={ prizesLink }>
									{promotion.configuration.config.menus?.prizes?.label[promotion.configuration.currentLanguage]}
									</span>
								</li>}
								{(promotion.configuration.config.menus?.gallery?.on) && <li>
									<span onClick={ galleryLink }>
									{promotion.configuration.config.menus.gallery.label[promotion.configuration.currentLanguage]}
									</span>
								</li>}
								{(promotion.configuration.config.menus?.rules?.on) && <li>
									<span onClick={ officialRulesLink }>
									{promotion.configuration.config.menus.rules.label[promotion.configuration.currentLanguage]}
									</span>
								</li>}
							</ul>
						</div>
					
						<div id="content">


							<BrowserRouterRoutes />


						
					</div>
					
						<div id="bottom_nav">
						
							<div id="links">
								<ul>
								{(promotion.configuration.config.menus?.enter?.on) && <li>
									<span onClick={ enterLink }>
										{promotion.configuration.config.menus.enter.label[promotion.configuration.currentLanguage]}
									</span>
								</li>}
								{(promotion.configuration.config.menus?.logprogress?.on) && <li>
									<span onClick={ logProgressLink }>
										{promotion.configuration.config.menus.logprogress.label[promotion.configuration.currentLanguage]}
									</span>
								</li>}
								{(promotion.configuration.config.menus?.about?.on) && <li>
									<span onClick={ aboutLink }>
										{promotion.configuration.config.menus.about.label[promotion.configuration.currentLanguage]}
									</span>
								</li>}
								{(promotion.configuration.config.menus?.faq?.on) && <li>
									<span onClick={ faqLink }>
										{promotion.configuration.config.menus.faq.label[promotion.configuration.currentLanguage]}
									</span>
								</li>}
								{(promotion.configuration.config.menus?.prizes?.on) && <li>
									<span onClick={ prizesLink }>
										{promotion.configuration.config.menus.prizes.label[promotion.configuration.currentLanguage]}
									</span>
								</li>}
								{(promotion.configuration.config.menus?.gallery?.on) && <li>
									<span onClick={ galleryLink }>
										{promotion.configuration.config.menus.gallery.label[promotion.configuration.currentLanguage]}
									</span>
								</li>}
								{(promotion.configuration.config.menus?.rules?.on) && <li>
									<span onClick={ officialRulesLink }>
										{promotion.configuration.config.menus.rules.label[promotion.configuration.currentLanguage]}
									</span>
								</li>}
								{(promotion.configuration.config.menus?.privacyPolicy?.on) && <li>
									<span onClick={() => privacyPolicyLink(promotion.configuration.promoCopy["GLOBAL"]["privacyPolicyLink"])}>
										{promotion.configuration.config.menus.privacyPolicy.label[promotion.configuration.currentLanguage]}
									</span>
								</li>}
							</ul>
						</div>
						<div className="clear"></div>

							{(promotion.configuration.name === "2024_05_30_RSP_MLB_TriviaTest_TEST" || promotion.configuration.name === "2024_07_01_MARSUNITED_BLUETRITON_SummerMLB") &&
								<iframe id="universal_pixel_hkfv77r" allowTransparency="true" height="0" width="0"
										src="https://insight.adsrvr.org/track/up?adv=v0n77dt&ref=https%3A%2F%2Fbaseball-trivia.com%2F&upid=hkfv77r&upv=1.1.0"
										title="TTD Universal Pixel"></iframe>}


						
						<Footer />
						
					</div>
				</div>
			</div>
			
		</>

);
			
			
		}

	}
  
}

export default App;



