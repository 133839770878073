import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";

import { logIt, replaceVars } from '__globals/global-functions';

var sanitizeHtml = require('sanitize-html');

export const Footer = props => {

	const promotion = useSelector(state => state.promotion);
	
	const date = new Date();
	const year = date.getFullYear();
	
	useEffect(() => {
		if ( 
			promotion.configuration.promoCopy !== undefined &&
			promotion.configuration.promoCopy.GLOBAL !== undefined &&
			promotion.configuration.promoCopy.GLOBAL.footerBody !== undefined
		) {
			let contentReplaced = replaceVars( promotion.configuration.promoCopy.GLOBAL.footerBody, promotion );
			if ( document && document.getElementById("all_legal")) {
				let cleanedContent = sanitizeHtml( contentReplaced, {
					allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img', 'style' ])
				});
				document.getElementById("all_legal").innerHTML = cleanedContent;
			}
		}
	});
		
	return (
		<div id="footer">
			<div className="clear"></div>
			<div id="footer_copy">
				<div id="all_legal">
						<strong>No Purchase Necessary to Enter or Win.</strong> Open to legal residents 
						of the 50 United States (including the District of Columbia) 18 or older 
						as of {promotion.configuration.config.startDate}. Starts 12:00:01 AM Eastern Standard Time (“EST”) 
						on {promotion.configuration.config.startDate} &amp; ends 11:59:59 PM EST on 
						{promotion.configuration.config.endDate}. For Official Rules, 
						which govern this sweepstakes, see <a className="rulesoverlay" data-fancybox-type="iframe" href="../rules.html">
						<strong>Official Rules</strong></a>. No transfer, assignment, cash redemption, 
						or substitution of prizes. Void where prohibited by law.
						<br/><br/>
						Copyright &copy; ${year} ${promotion.configuration.config.companyName}. All rights reserved.
				</div>
			</div>
            <div className="clear"></div>
			<div id="poweredby">
				Powered by <a href="http://www.readysetpromo.com" target="_blank">ReadySetPromo.com</a>
			</div>
            <div id="administeredby">
				Administered by <a href={promotion.configuration.config.administeredByURL} target="_blank" link="link4">{promotion.configuration.config.administeredByName}</a> 
			</div> 
		</div>
	);
}

