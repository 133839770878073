import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { QrReader } from 'react-qr-reader';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';
import {ActionButtons} from "_components/ActionButtons/ActionButtons";
import {PageCopy} from "_components/PageCopy/PageCopy";
import {PromocracyLogo} from "_components/PromocracyLogo/PromocracyLogo";
import PageFlow from "_pages/__routes/PageFlow";
import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';
import {PageBackgroundImages} from "_components/PageBackgroundImages/PageBackgroundImages";
import {IFrameInsert} from "_components/IFrameInsert/IFrameInsert";

import { executeAction } from "__globals/actions";
import * as GC from "__globals/GLOBAL-CONSTANTS";
import {
    empty,
    getSlashSourceFromUrlString,
    logIt,
} from '__globals/global-functions';
import {
    createAddEntryForTicketProviderScan,
    retrievePromocracyParticipant,
    storeTicketScanInLocalStorage,
    hasProviderBeenScanned, isRaffleTicketValidatorCode
} from "__globals/promocracy-functions";
import {
    processClearRaffleEntry,
    updateEntry
} from "_features/entries/entry-slice";



export const RaffleScan = props => {

    logIt( GC.PAGE_RAFFLE_SCAN + " PAGE IS CALLED", null, false );
    const PAGE_DIV_NAME = "raffle_scan"; // use underscores
    const PAGE_KEY = "raffleScan"; // use camel case
    const PAGE_IMAGE_KEY = "raffle-scan"; // use dashes
    const PAGE_JSON_KEY = GC.PAGE_RAFFLE_SCAN; // use init caps

    const dispatch = useDispatch();

    const promotion = useSelector(state => state.promotion);
    //logIt( "PROMOTION at " + GC.PAGE_RAFFLE_SCAN, promotion, true );

    const entry = useSelector( state => state.entry );

    let entryUpdated = useSelector(state => state.entry.entryUpdated);

    // LOCAL VARIABLES
    const [action, setAction] = useState('');
    const [value, setValue] = useState('');
    const [goNext, setGoNext] = useState(false);

    const [qrData, setQrData] = useState('No result');
    const [scan, setScan] = useState(false);
    const [alreadyScanned, setAlreadyScanned] = useState(false);

    const [ticketProvider, setTicketProvider] = useState('');

    const FAKE_TEST_DATA = "https://someurl.something.com/HarleyDavidson";
    //const FAKE_TEST_DATA = "https://someurl.something.com/KabukiSushi";

    const handleScanAction = () => {
        setScan( true );
    }

    const doAction = ( a, v ) => {
        if ( a.toUpperCase().trim() === GC.ACTION_NAVIGATE ) {
            setAction(a);
            setValue(v);
            setGoNext(true);
        } else {
            dispatch( executeAction(a, v) );
        }
    }

    useEffect(() => {
        if (!empty(qrData) && (qrData !== 'No result') ) {
            let slashSource = getSlashSourceFromUrlString( qrData );
            let tp = retrievePromocracyParticipant( promotion.configuration, slashSource );
            setTicketProvider( tp );
        } else {
            if ( scan ) {
                logIt("CLEARING THE RAFFLE ENTRY", null, false);
                dispatch(processClearRaffleEntry());
            }
        }
    }, [qrData]);

    useEffect(() => {
        if ( !empty(ticketProvider) ) {
            let addEntry = createAddEntryForTicketProviderScan( ticketProvider );
            logIt( "ADDING RAFFLE TICKET ENTRY", addEntry, true );
            dispatch( updateEntry(addEntry) );
        }

    }, [ticketProvider]);

    useEffect(() => {
        if ( !empty(entry) && !empty(entry?.variables?.ticketProvider) ) {
            if ( entryUpdated ) {
                if ( hasProviderBeenScanned(ticketProvider) ) {
                    logIt("TICKET PROVIDER WAS ALREADY STORED", ticketProvider, true);
                    doAction( "NAVIGATE", "already-scanned" );
                } else {
                    logIt("STORING TICKET PROVIDER", ticketProvider, true);
                    storeTicketScanInLocalStorage(ticketProvider)
                }
                setQrData( 'No result' );
            }
       } else {
            if ( scan ) {
                logIt("SETTING SCAN TO FALSE", null, false);
                setScan(false);
                logIt( "RAFFLE WAS CLEARED, SETTING GO NEXT", null, false );
                setGoNext( true );
            }
        }
    }, [entryUpdated] );

    if (goNext) {

        return (<PageFlow page={GC.PAGE_RAFFLE_SCAN} error={null} action={action} value={value} />);

    } else {

        return (
            <React.Fragment>
                <TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title}/>
                <div id={PAGE_DIV_NAME + "_page"}>

                    <table>
                        <tbody>
                        <tr>
                            <td className="page-visual">

                                <PageBackgroundImages
                                    promotion={promotion}
                                    pageKey={PAGE_IMAGE_KEY}
                                />

                            </td>
                            <td className="page-content">

                                <div id={PAGE_DIV_NAME + "_content"}>

                                    <PageCopy
                                        promotion={promotion}
                                        configKey={PAGE_KEY}
                                        jsonKey={PAGE_JSON_KEY}
                                    />

                                    { (scan) ? (
                                        <QrReader
                                            onResult={(result, error) => {
                                                if (!!result) {
                                                    setQrData(result?.text);
                                                }

                                                if (!!error) {
                                                    console.info(error);
                                                }
                                            }}
                                            style={{width: '100%'}}
                                            constraints={{facingMode: 'environment'}}
                                        />
                                    ) : (
                                        <div id="scan_button" onClick={handleScanAction}>Scan QR Code</div>
                                    )}

                                    <ActionButtons
                                        promotion={promotion}
                                        configKey={PAGE_KEY}
                                        jsonKey={PAGE_JSON_KEY}
                                        doAction={doAction}
                                    />

                                </div>

                                <PromocracyLogo promotion={promotion}/>

                                <IFrameInsert
                                    promotion={promotion}
                                    configKey={PAGE_KEY}
                                />

                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <CustomContent/>

                </div>
            </React.Fragment>

        );

    }

}

