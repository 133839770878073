/*
 * NAME: iw-functions.js
 * DESCRIPTION: A global set of functions to help with iinstant win games.
 * AUTHOR: Chuck Gorder
 * CREATED: 2022-07-07
 * 
 */
import { 	
	logIt, 
	empty
} from '__globals/global-functions';

/* Retrieves the game name from the promotion with some error checks */
export function getGameName( promotion ) {
	
	let gameName = "";
	if ( !empty(promotion?.configuration?.config) ) {
		if (promotion.configuration.type.value === "iw-wheel") {
			gameName = promotion.configuration.config.games.wheel.gameName;
		} else if (promotion.configuration.type.value === "iw-match") {
			gameName = promotion.configuration.config.games.match.gameName;
		} else if (promotion.configuration.type.value === "iw-slot") {
			gameName = promotion.configuration.config.games.slot.gameName;
		} else if (promotion.configuration.type.value === "iw-match-configurable") {
			gameName = promotion.configuration.config.games.match.gameName;
		} else if (promotion.configuration.type.value === "iw-no-click-reveal") {
			gameName = promotion.configuration.config.games.reveal.gameName;
		}
	}

	return gameName;
	
}